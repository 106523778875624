.argonEditorRender {
  ol li,
  ul li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.2rem;
    /* color: #525f7f; */
    margin-left: 2rem;
  }

  .small {
    p {
      font-size: 0.8rem;
    }
  }
}

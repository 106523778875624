.glass {
  /* background-color: rgba(255, 255, 255, 0.15); */
  backdrop-filter: saturate(120%) blur(10px);
  -webkit-backdrop-filter: saturate(120%) blur(10px);
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  transform: translate3d(0, 0, 0);
}

.glass-light {
  /* background-color: rgba(255, 255, 255, 0.15); */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  transform: translate3d(0, 0, 0);
}

.glass-white {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  transform: translate3d(0, 0, 0);
}

/*! Standard formatting (for organizer), for other variants see sub-classes below */

.ka {
  overflow: hidden;
  background-color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  /* box-shadow: 0 0 0 0px #e1ebf0; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ka-table-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.ka-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ka-thead-background {
  font-size: 12px;
  background-color: #ffffff;
}

.ka-thead-cell-height {
  box-sizing: border-box;
  height: 47px;
}

.ka-thead-fixed {
  position: sticky;
}

.ka-thead-row:nth-child(1) .ka-thead-fixed {
  top: 0px;
}

.ka-thead-row:nth-child(2) .ka-thead-fixed {
  top: 47px;
}

.ka-thead-row:nth-child(3) .ka-thead-fixed {
  top: 94px;
}

.ka-thead-row:nth-child(4) .ka-thead-fixed {
  top: 141px;
}

.ka-thead-row:nth-child(5) .ka-thead-fixed {
  top: 188px;
}

.ka-thead-row:nth-child(6) .ka-thead-fixed {
  top: 235px;
}

.ka-thead-cell {
  padding: 15px 20px;
  color: #747d86;
  /* color: #ffffff; */
}

.ka-thead-cell-wrapper {
  display: flex;
}

.ka-thead-cell-content-wrapper {
  width: 100%;
}

.ka-thead-cell-content {
  width: 100%;
}

.ka-tbody {
  height: 100%;
}

.ka-thead-cell,
.ka-cell {
  text-align: left;
}

.ka-cell {
  padding: 8px 20px;
  line-height: 29px;
  color: #353c44;
  font-size: 12px;
}

.ka-tree-cell {
  display: flex;
}

.ka-icon {
  font-weight: 100;
  font-size: 10px;
  font-family: 'icons';
}

.ka-input {
  max-width: 100%;
  border: 1px solid;
}

.ka-cell-editor-validation-error .ka-input {
  background: #ffe7e7;
  border: 1px solid #ff0c0c;
}

.ka-validation-message-container {
  position: absolute;
}

.ka-validation-message {
  font-size: 12px;
  color: white;
  background-color: #ff0c0c;
  padding: 0 10px;
  max-width: 200px;
}

.ka-empty-cell {
  width: 40px;
  min-width: 40px;
}

.ka-tree-empty-space {
  width: 20px;
}

.ka-tr {
  width: 100%;
}

.ka-no-data-row {
  height: 100px;
  text-align: center;
}

.ka-row {
  box-sizing: border-box;
  border-bottom: 2px solid #f9fbfc;
  border-top: 2px solid #f9fbfc;
}

.ka-row:hover {
  background-color: #ddd;
  cursor: pointer;
}

.ka-dragged-row {
  opacity: 0.5;
}

.ka-drag-over-row {
  box-shadow: inset 0 7px 0px -4px #e1ebf0;
}

.ka-dragged-row ~ .ka-drag-over-row {
  box-shadow: inset 0 -7px 0px -4px #e1ebf0;
}

.ka-drag-over-column {
  box-shadow: inset 7px 0 0px -4px #e1ebf0;
}

.ka-dragged-column ~ .ka-drag-over-column {
  box-shadow: inset -7px 0 0px -4px #e1ebf0;
}

.ka-row-selected {
  background-color: #f7fcfd;
  /* background-color: red; */
}

.ka-group-row {
  background-color: #f9fbfc;
  box-sizing: border-box;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.ka-group-cell {
  padding: 8px 10px;
}

.ka-group-cell-content {
  display: flex;
  align-items: center;
}

.ka-icon-group-arrow {
  padding: 7px 5px 7px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.ka-group-panel {
  display: flex;
}

.ka-group-panel-cell {
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 15px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.ka-group-panel-cell-remove {
  cursor: pointer;
  color: #b8bdc2;
  display: flex;
  fill: currentColor;
}

.ka-group-panel-text {
  color: #747d86;
  margin: 30px 20px;
}

.ka-icon-tree-arrow {
  padding: 0 10px 0 10px;
  margin-left: -10px;
  cursor: pointer;
}

.ka-icon-sort {
  margin-left: 3px;
}

.ka-icon-sort-arrow-up {
  color: #687078;
  fill: currentColor;
  position: relative;
  top: 1px;
}

.ka-icon-sort-arrow-down {
  color: #687078;
  fill: currentColor;
  position: relative;
  top: 1px;
}

.ka-pointer {
  cursor: pointer;
}

.ka-filter-row-cell {
  padding: 0 20px 15px 20px;
}

.ka-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5333333333);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ka-loading-active {
  position: relative;
}

.ka-loading-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: ka-loading-animation 1s linear infinite alternate;
  position: relative;
  left: -20px;
}

.ka-thead-cell-resize {
  user-select: none;
  width: 2px;
  cursor: col-resize;
  background-color: #d7e4eb;
  position: relative;
  left: 19px;
}

.ka-thead-cell-resize:active:after {
  content: '';
  display: block;
  position: fixed;
  cursor: col-resize;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ka-thead-background:active {
  z-index: 1;
}

.ka-summary-cell {
  padding: 12px 20px;
}

@keyframes ka-loading-animation {
  0% {
    background-color: rgb(116, 125, 134);
    box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
      40px 0px 0px 0px rgba(116, 125, 134, 0.2);
  }
  25% {
    background-color: rgba(116, 125, 134, 0.4);
    box-shadow: 20px 0px 0px 0px rgb(116, 125, 134),
      40px 0px 0px 0px rgba(116, 125, 134, 0.2);
  }
  75% {
    background-color: rgba(116, 125, 134, 0.4);
    box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
      40px 0px 0px 0px rgb(116, 125, 134);
  }
  100% {
    box-shadow: none;
  }
}
.ka-loading-text {
  margin-top: 15px;
  color: #353c44;
}

.ka-paging-sizes-active {
  display: flex;
  justify-content: space-between;
}

.ka-paging-pages,
.ka-paging-sizes {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  margin: 0;
}

.ka-paging-pages {
  justify-content: flex-end;
}

.ka-paging-page-index,
.ka-paging-size {
  cursor: pointer;
  /* padding: 5px; */
  margin: 10px 5px;
  min-width: 18px;
  border-radius: 50%;
  text-align: center;
  color: #929597;
  user-select: none;
  font-size: 14px;
}

.ka-paging-page-index-active,
.ka-paging-size-active {
  /* background-color: #f1f5f7; */
  font-weight: bold;
  color: #747d86;
  font-size: 14px;
}

.ka-popup {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  color: #747d86;
  padding: 15px 20px;
  position: absolute;
  text-align: center;
  width: 245px;
  max-height: 325px;
  overflow-y: auto;
  z-index: 1000;
}

.ka-popup-content-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f9fbfc;
}

.ka-popup-content-item-value {
  padding: 8px 20px;
}

.ka-header-filter-button-icon {
  font-size: 16px;
  position: absolute;
  top: -1px;
  color: #51575d;
  fill: currentColor;
}

.ka-header-filter-button {
  margin-left: 3px;
  position: relative;
}

.ka-header-filter-button-has-value {
  font-weight: bold;
}

/*! other css variations */

.ka-table-transparent {
  .ka {
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0);
    font-size: 14px;

    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* border: solid 1px #e1ebf0; */
    box-shadow: 0 0 0 0px #e1ebf0;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* backdrop-filter: blur(0px); */
    color: white;
  }

  .ka-table-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .ka-table {
    line-height: 1.1rem;

    width: 100%;
    table-layout: fixed;
    border-collapse: collapse !important;
    /* color: white; */
  }

  .ka-thead-background {
    font-size: 12px;
    background-color: rgb(0, 0, 0, 0);
  }

  .ka-thead-cell-height {
    box-sizing: border-box;
    height: 47px;
  }

  .ka-thead-fixed {
    position: sticky;
  }

  .ka-thead-row:nth-child(1) .ka-thead-fixed {
    top: 0px;
  }

  .ka-thead-row:nth-child(2) .ka-thead-fixed {
    top: 47px;
  }

  .ka-thead-row:nth-child(3) .ka-thead-fixed {
    top: 94px;
  }

  .ka-thead-row:nth-child(4) .ka-thead-fixed {
    top: 141px;
  }

  .ka-thead-row:nth-child(5) .ka-thead-fixed {
    top: 188px;
  }

  .ka-thead-row:nth-child(6) .ka-thead-fixed {
    top: 235px;
  }

  .ka-thead-cell {
    padding: 15px 20px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 800;
    border-bottom: 2px solid #f9fbfc5d;

    /* color: #747d86; */
    /* color: #ffffff; */
    /* border-bottom: 2px solid #f9fbfc5d; */
    /* border-bottom: 0px solid white; */
    /* border: 0px !important; */
  }

  .ka-thead-cell-wrapper {
    display: flex;
  }

  .ka-thead-cell-content-wrapper {
    width: 100%;
  }

  .ka-thead-cell-content {
    width: 100%;
  }

  .ka-tbody {
    height: 100%;
  }

  .ka-thead-cell,
  .ka-cell {
    text-align: left;
    color: white;
  }

  .ka-cell {
    padding: 8px 20px;
    line-height: 1.1rem;
    /* color: #353c44; */
    /* color: #ffffff;
  font-size: 14px;
  font-weight: 800; */

    /* border: solid 0px #e1ebf0; */
  }

  .ka-tree-cell {
    display: flex;
  }

  .ka-icon {
    font-weight: 100;
    font-size: 10px;
    font-family: 'icons';
  }

  .ka-input {
    max-width: 100%;
    border: 1px solid;
  }

  .ka-cell-editor-validation-error .ka-input {
    background: #ffe7e7;
    border: 1px solid #ff0c0c;
  }

  .ka-validation-message-container {
    position: absolute;
  }

  .ka-validation-message {
    font-size: 12px;
    color: white;
    background-color: #ff0c0c;
    padding: 0 10px;
    max-width: 200px;
  }

  .ka-empty-cell {
    width: 40px;
    min-width: 40px;
  }

  .ka-tree-empty-space {
    width: 20px;
  }

  .ka-tr {
    width: 100%;
  }

  .ka-no-data-row {
    height: 100px;
    text-align: center;
  }

  .ka-row {
    box-sizing: border-box;
    border-bottom: 0px solid #f9fbfc;
    border-top: 0px solid #f9fbfc;
  }

  .ka-row:hover {
    background-color: rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  .ka-dragged-row {
    opacity: 0.5;
  }

  .ka-drag-over-row {
    box-shadow: inset 0 7px 0px -4px #e1ebf0;
  }

  .ka-dragged-row ~ .ka-drag-over-row {
    box-shadow: inset 0 -7px 0px -4px #e1ebf0;
  }

  .ka-drag-over-column {
    box-shadow: inset 7px 0 0px -4px #e1ebf0;
  }

  .ka-dragged-column ~ .ka-drag-over-column {
    box-shadow: inset -7px 0 0px -4px #e1ebf0;
  }

  .ka-row-selected {
    background-color: #f7fcfd;
    /* background-color: red; */
  }

  .ka-group-row {
    background-color: #f9fbfc;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    /* border-top: 1px solid white; */
  }

  .ka-group-cell {
    padding: 8px 10px;
  }

  .ka-group-cell-content {
    display: flex;
    align-items: center;
  }

  .ka-icon-group-arrow {
    padding: 7px 5px 7px 10px;
    margin-right: 5px;
    cursor: pointer;
  }

  .ka-group-panel {
    display: flex;
  }

  .ka-group-panel-cell {
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .ka-group-panel-cell-remove {
    cursor: pointer;
    color: #b8bdc2;
    display: flex;
    fill: currentColor;
  }

  .ka-group-panel-text {
    color: #747d86;
    margin: 30px 20px;
  }

  .ka-icon-tree-arrow {
    padding: 0 10px 0 10px;
    margin-left: -10px;
    cursor: pointer;
  }

  .ka-icon-sort {
    margin-left: 3px;
  }

  .ka-icon-sort-arrow-up {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-icon-sort-arrow-down {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-pointer {
    cursor: pointer;
  }

  .ka-filter-row-cell {
    padding: 0 20px 15px 20px;
  }

  .ka-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5333333333);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ka-loading-active {
    position: relative;
  }

  .ka-loading-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: ka-loading-animation 1s linear infinite alternate;
    position: relative;
    left: -20px;
  }

  .ka-thead-cell-resize {
    user-select: none;
    width: 2px;
    cursor: col-resize;
    background-color: #d7e4eb;
    position: relative;
    left: 19px;
  }

  .ka-thead-cell-resize:active:after {
    content: '';
    display: block;
    position: fixed;
    cursor: col-resize;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ka-thead-background:active {
    z-index: 1;
  }

  .ka-summary-cell {
    padding: 12px 20px;
  }

  @keyframes ka-loading-animation {
    0% {
      background-color: rgb(116, 125, 134);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    25% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgb(116, 125, 134),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    75% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgb(116, 125, 134);
    }
    100% {
      box-shadow: none;
    }
  }
  .ka-loading-text {
    margin-top: 15px;
    color: #353c44;
  }

  .ka-paging-sizes-active {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #94aab4;
  }

  .ka-paging-pages,
  .ka-paging-sizes {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    margin: 0;
  }

  .ka-paging-pages {
    justify-content: flex-end;
  }

  .ka-paging-page-index,
  .ka-paging-size {
    cursor: pointer;
    padding: 0 3px;
    margin: 10px 5px;
    min-width: 18px;
    /* border-radius: 50%; */
    text-align: center;
    color: #e4e9f5;
    user-select: none;
  }

  .ka-paging-page-index-active,
  .ka-paging-size-active {
    /* background-color: #f1f5f7; */
    font-weight: bold;
    /* color: #747d86; */
    color: #ffffff;
    font-weight: 800;
    padding: 0px 3px;
  }

  .ka-popup {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    color: #747d86;
    padding: 15px 20px;
    position: absolute;
    text-align: center;
    width: 245px;
    max-height: 325px;
    overflow-y: auto;
    z-index: 1000;
  }

  .ka-popup-content-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f9fbfc;
  }

  .ka-popup-content-item-value {
    padding: 8px 20px;
  }

  .ka-header-filter-button-icon {
    font-size: 16px;
    position: absolute;
    top: -1px;
    color: #51575d;
    fill: currentColor;
  }

  .ka-header-filter-button {
    margin-left: 3px;
    position: relative;
  }

  .ka-header-filter-button-has-value {
    font-weight: bold;
  }
}

.ka-table_transparent_dark_font {
  .ka {
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0);
    font-size: 14px;

    display: flex;
    flex-direction: column;
    border-radius: 0px;
    /* border: solid 1px #e1ebf0; */
    box-shadow: 0 0 0 0px #e1ebf0;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    backdrop-filter: blur(0px);
    color: #67748e;
  }

  .ka-table-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .ka-table {
    line-height: 1.1rem;

    width: 100%;
    table-layout: fixed;
    border-collapse: collapse !important;
    /* color: white; */
  }

  .ka-thead-background {
    font-size: 12px;
    background-color: rgb(0, 0, 0, 0);
  }

  .ka-thead-cell-height {
    box-sizing: border-box;
    height: 47px;
  }

  .ka-thead-fixed {
    position: sticky;
  }

  .ka-thead-row:nth-child(1) .ka-thead-fixed {
    top: 0px;
  }

  .ka-thead-row:nth-child(2) .ka-thead-fixed {
    top: 47px;
  }

  .ka-thead-row:nth-child(3) .ka-thead-fixed {
    top: 94px;
  }

  .ka-thead-row:nth-child(4) .ka-thead-fixed {
    top: 141px;
  }

  .ka-thead-row:nth-child(5) .ka-thead-fixed {
    top: 188px;
  }

  .ka-thead-row:nth-child(6) .ka-thead-fixed {
    top: 235px;
  }

  .ka-thead-cell {
    padding: 15px 20px;
    /* color: #ffffff; */
    color: #67748e;
    font-size: 14px;
    font-weight: 800;
    border-bottom: 2px solid #f9fbfc5d;

    /* color: #747d86; */
    /* color: #ffffff; */
    /* border-bottom: 2px solid #f9fbfc5d; */
    /* border-bottom: 0px solid white; */
    /* border: 0px !important; */
  }

  .ka-thead-cell-wrapper {
    display: flex;
  }

  .ka-thead-cell-content-wrapper {
    width: 100%;
  }

  .ka-thead-cell-content {
    width: 100%;
  }

  .ka-tbody {
    height: 100%;
  }

  .ka-thead-cell,
  .ka-cell {
    text-align: left;
    /* color: white; */
    color: #67748e;
  }

  .ka-cell {
    padding: 8px 20px;
    line-height: 1.1rem;
    /* color: #353c44; */
    /* color: #ffffff;
  font-size: 14px;
  font-weight: 800; */

    /* border: solid 0px #e1ebf0; */
  }

  .ka-tree-cell {
    display: flex;
  }

  .ka-icon {
    font-weight: 100;
    font-size: 10px;
    font-family: 'icons';
  }

  .ka-input {
    max-width: 100%;
    border: 1px solid;
  }

  .ka-cell-editor-validation-error .ka-input {
    background: #ffe7e7;
    border: 1px solid #ff0c0c;
  }

  .ka-validation-message-container {
    position: absolute;
  }

  .ka-validation-message {
    font-size: 12px;
    /* color: white; */
    color: #67748e;
    background-color: #ff0c0c;
    padding: 0 10px;
    max-width: 200px;
  }

  .ka-empty-cell {
    width: 40px;
    min-width: 40px;
  }

  .ka-tree-empty-space {
    width: 20px;
  }

  .ka-tr {
    width: 100%;
  }

  .ka-no-data-row {
    height: 100px;
    text-align: center;
  }

  .ka-row {
    box-sizing: border-box;
    border-bottom: 0px solid #f9fbfc;
    border-top: 0px solid #f9fbfc;
  }

  .ka-row:hover {
    background-color: rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  .ka-dragged-row {
    opacity: 0.5;
  }

  .ka-drag-over-row {
    box-shadow: inset 0 7px 0px -4px #e1ebf0;
  }

  .ka-dragged-row ~ .ka-drag-over-row {
    box-shadow: inset 0 -7px 0px -4px #e1ebf0;
  }

  .ka-drag-over-column {
    box-shadow: inset 7px 0 0px -4px #e1ebf0;
  }

  .ka-dragged-column ~ .ka-drag-over-column {
    box-shadow: inset -7px 0 0px -4px #e1ebf0;
  }

  .ka-row-selected {
    background-color: #f7fcfd;
    /* background-color: red; */
  }

  .ka-group-row {
    background-color: #f9fbfc;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    /* border-top: 1px solid white; */
  }

  .ka-group-cell {
    padding: 8px 10px;
  }

  .ka-group-cell-content {
    display: flex;
    align-items: center;
  }

  .ka-icon-group-arrow {
    padding: 7px 5px 7px 10px;
    margin-right: 5px;
    cursor: pointer;
  }

  .ka-group-panel {
    display: flex;
  }

  .ka-group-panel-cell {
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .ka-group-panel-cell-remove {
    cursor: pointer;
    color: #b8bdc2;
    display: flex;
    fill: currentColor;
  }

  .ka-group-panel-text {
    color: #747d86;
    margin: 30px 20px;
  }

  .ka-icon-tree-arrow {
    padding: 0 10px 0 10px;
    margin-left: -10px;
    cursor: pointer;
  }

  .ka-icon-sort {
    margin-left: 3px;
  }

  .ka-icon-sort-arrow-up {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-icon-sort-arrow-down {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-pointer {
    cursor: pointer;
  }

  .ka-filter-row-cell {
    padding: 0 20px 15px 20px;
  }

  .ka-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5333333333);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ka-loading-active {
    position: relative;
  }

  .ka-loading-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: ka-loading-animation 1s linear infinite alternate;
    position: relative;
    left: -20px;
  }

  .ka-thead-cell-resize {
    user-select: none;
    width: 2px;
    cursor: col-resize;
    background-color: #d7e4eb;
    position: relative;
    left: 19px;
  }

  .ka-thead-cell-resize:active:after {
    content: '';
    display: block;
    position: fixed;
    cursor: col-resize;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ka-thead-background:active {
    z-index: 1;
  }

  .ka-summary-cell {
    padding: 12px 20px;
  }

  @keyframes ka-loading-animation {
    0% {
      background-color: rgb(116, 125, 134);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    25% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgb(116, 125, 134),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    75% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgb(116, 125, 134);
    }
    100% {
      box-shadow: none;
    }
  }
  .ka-loading-text {
    margin-top: 15px;
    color: #353c44;
  }

  .ka-paging-sizes-active {
    display: flex;
    justify-content: space-between;
    /* border-top: 2px solid #94aab4; */
    border-top: 2px solid #f9fbfc5d;
  }

  .ka-paging-pages,
  .ka-paging-sizes {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    margin: 0;
  }

  .ka-paging-pages {
    justify-content: flex-end;
  }

  .ka-paging-page-index,
  .ka-paging-size {
    cursor: pointer;
    padding: 0 3px;
    margin: 10px 5px;
    min-width: 18px;
    /* border-radius: 50%; */
    text-align: center;
    /* color: #e4e9f5; */
    color: #67748e;
    user-select: none;
  }

  .ka-paging-page-index-active,
  .ka-paging-size-active {
    /* background-color: #f1f5f7; */
    font-weight: bold;
    /* color: #747d86; */
    /* color: #ffffff; */
    color: #67748e;
    font-weight: 800;
    padding: 0px 3px;
  }

  .ka-popup {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    color: #747d86;
    padding: 15px 20px;
    position: absolute;
    text-align: center;
    width: 245px;
    max-height: 325px;
    overflow-y: auto;
    z-index: 1000;
  }

  .ka-popup-content-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f9fbfc;
  }

  .ka-popup-content-item-value {
    padding: 8px 20px;
  }

  .ka-header-filter-button-icon {
    font-size: 16px;
    position: absolute;
    top: -1px;
    color: #51575d;
    fill: currentColor;
  }

  .ka-header-filter-button {
    margin-left: 3px;
    position: relative;
  }

  .ka-header-filter-button-has-value {
    font-weight: bold;
  }
}

.ka-table_transparent_dark_font_small_paddings {
  .ka {
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0);
    font-size: 14px;

    display: flex;
    flex-direction: column;
    border-radius: 0px;
    /* border: solid 1px #e1ebf0; */
    box-shadow: 0 0 0 0px #e1ebf0;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    backdrop-filter: blur(0px);
    color: #67748e;
  }

  .ka-table-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .ka-table {
    line-height: 1.1rem;

    width: 100%;
    table-layout: fixed;
    border-collapse: collapse !important;
    /* color: white; */
  }

  .ka-thead-background {
    font-size: 12px;
    background-color: rgb(0, 0, 0, 0);
  }

  .ka-thead-cell-height {
    box-sizing: border-box;
    height: 47px;
  }

  .ka-thead-fixed {
    position: sticky;
  }

  .ka-thead-row:nth-child(1) .ka-thead-fixed {
    top: 0px;
  }

  .ka-thead-row:nth-child(2) .ka-thead-fixed {
    top: 47px;
  }

  .ka-thead-row:nth-child(3) .ka-thead-fixed {
    top: 94px;
  }

  .ka-thead-row:nth-child(4) .ka-thead-fixed {
    top: 141px;
  }

  .ka-thead-row:nth-child(5) .ka-thead-fixed {
    top: 188px;
  }

  .ka-thead-row:nth-child(6) .ka-thead-fixed {
    top: 235px;
  }

  .ka-thead-cell {
    padding: 0px 0px;
    /* color: #ffffff; */
    color: #67748e;
    font-size: 14px;
    font-weight: 800;
    border-bottom: 2px solid #f9fbfc5d;

    /* color: #747d86; */
    /* color: #ffffff; */
    /* border-bottom: 2px solid #f9fbfc5d; */
    /* border-bottom: 0px solid white; */
    /* border: 0px !important; */
  }

  .ka-thead-cell-wrapper {
    display: flex;
  }

  .ka-thead-cell-content-wrapper {
    width: 100%;
  }

  .ka-thead-cell-content {
    width: 100%;
  }

  .ka-tbody {
    height: 100%;
  }

  .ka-thead-cell,
  .ka-cell {
    text-align: left;
    /* color: white; */
    color: #67748e;
  }

  .ka-cell {
    padding: 5px 0px;
    line-height: 1.1rem;
    /* color: #353c44; */
    /* color: #ffffff;
  font-size: 14px;
  font-weight: 800; */

    /* border: solid 0px #e1ebf0; */
  }

  .ka-tree-cell {
    display: flex;
  }

  .ka-icon {
    font-weight: 100;
    font-size: 10px;
    font-family: 'icons';
  }

  .ka-input {
    max-width: 100%;
    border: 1px solid;
  }

  .ka-cell-editor-validation-error .ka-input {
    background: #ffe7e7;
    border: 1px solid #ff0c0c;
  }

  .ka-validation-message-container {
    position: absolute;
  }

  .ka-validation-message {
    font-size: 12px;
    /* color: white; */
    color: #67748e;
    background-color: #ff0c0c;
    padding: 0 10px;
    max-width: 200px;
  }

  .ka-empty-cell {
    width: 40px;
    min-width: 40px;
  }

  .ka-tree-empty-space {
    width: 20px;
  }

  .ka-tr {
    width: 100%;
  }

  .ka-no-data-row {
    height: 100px;
    text-align: center;
  }

  .ka-row {
    box-sizing: border-box;
    border-bottom: 0px solid #f9fbfc;
    border-top: 0px solid #f9fbfc;
  }

  .ka-row:hover {
    background-color: rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  .ka-dragged-row {
    opacity: 0.5;
  }

  .ka-drag-over-row {
    box-shadow: inset 0 7px 0px -4px #e1ebf0;
  }

  .ka-dragged-row ~ .ka-drag-over-row {
    box-shadow: inset 0 -7px 0px -4px #e1ebf0;
  }

  .ka-drag-over-column {
    box-shadow: inset 7px 0 0px -4px #e1ebf0;
  }

  .ka-dragged-column ~ .ka-drag-over-column {
    box-shadow: inset -7px 0 0px -4px #e1ebf0;
  }

  .ka-row-selected {
    background-color: #f7fcfd;
    /* background-color: red; */
  }

  .ka-group-row {
    background-color: #f9fbfc;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    /* border-top: 1px solid white; */
  }

  .ka-group-cell {
    padding: 8px 10px;
  }

  .ka-group-cell-content {
    display: flex;
    align-items: center;
  }

  .ka-icon-group-arrow {
    padding: 7px 5px 7px 10px;
    margin-right: 5px;
    cursor: pointer;
  }

  .ka-group-panel {
    display: flex;
  }

  .ka-group-panel-cell {
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .ka-group-panel-cell-remove {
    cursor: pointer;
    color: #b8bdc2;
    display: flex;
    fill: currentColor;
  }

  .ka-group-panel-text {
    color: #747d86;
    margin: 30px 20px;
  }

  .ka-icon-tree-arrow {
    padding: 0 10px 0 10px;
    margin-left: -10px;
    cursor: pointer;
  }

  .ka-icon-sort {
    margin-left: 3px;
  }

  .ka-icon-sort-arrow-up {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-icon-sort-arrow-down {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-pointer {
    cursor: pointer;
  }

  .ka-filter-row-cell {
    padding: 0 20px 15px 20px;
  }

  .ka-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5333333333);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ka-loading-active {
    position: relative;
  }

  .ka-loading-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: ka-loading-animation 1s linear infinite alternate;
    position: relative;
    left: -20px;
  }

  .ka-thead-cell-resize {
    user-select: none;
    width: 2px;
    cursor: col-resize;
    background-color: #d7e4eb;
    position: relative;
    left: 19px;
  }

  .ka-thead-cell-resize:active:after {
    content: '';
    display: block;
    position: fixed;
    cursor: col-resize;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ka-thead-background:active {
    z-index: 1;
  }

  .ka-summary-cell {
    padding: 12px 20px;
  }

  @keyframes ka-loading-animation {
    0% {
      background-color: rgb(116, 125, 134);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    25% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgb(116, 125, 134),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    75% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgb(116, 125, 134);
    }
    100% {
      box-shadow: none;
    }
  }
  .ka-loading-text {
    margin-top: 15px;
    color: #353c44;
  }

  .ka-paging-sizes-active {
    display: flex;
    justify-content: space-between;
    /* border-top: 2px solid #94aab4; */
    border-top: 2px solid #f9fbfc5d;
  }

  .ka-paging-pages,
  .ka-paging-sizes {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 0px;
    margin: 0;
  }

  .ka-paging-pages {
    justify-content: flex-end;
  }

  .ka-paging-pages::before {
    content: 'Page:';
    margin: 5px 5px;
    text-align: center;
  }

  .ka-paging-page-index,
  .ka-paging-size {
    cursor: pointer;
    padding: 0 0px;
    margin: 5px 0px;
    margin-right: 5px;
    min-width: 18px;
    /* border-radius: 50%; */
    text-align: center;
    /* color: #e4e9f5; */
    color: #67748e;
    user-select: none;
  }

  .ka-paging-page-index-active,
  .ka-paging-size-active {
    /* background-color: #f1f5f7; */
    font-weight: bold;
    /* color: #747d86; */
    /* color: #ffffff; */
    color: #67748e;
    font-weight: 800;
    padding: 0px 3px;
  }

  .ka-popup {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    color: #747d86;
    padding: 15px 20px;
    position: absolute;
    text-align: center;
    width: 245px;
    max-height: 325px;
    overflow-y: auto;
    z-index: 1000;
  }

  .ka-popup-content-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f9fbfc;
  }

  .ka-popup-content-item-value {
    padding: 8px 20px;
  }

  .ka-header-filter-button-icon {
    font-size: 16px;
    position: absolute;
    top: -1px;
    color: #51575d;
    fill: currentColor;
  }

  .ka-header-filter-button {
    margin-left: 3px;
    position: relative;
  }

  .ka-header-filter-button-has-value {
    font-weight: bold;
  }
}

.ka-table_transparent_dark_font_small {
  .ka {
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0);
    font-size: 12px;

    display: flex;
    flex-direction: column;
    border-radius: 0px;
    /* border: solid 1px #e1ebf0; */
    box-shadow: 0 0 0 0px #e1ebf0;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    backdrop-filter: blur(0px);
    color: #67748e;
  }

  .ka-table-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .ka-table {
    line-height: 1.1rem;

    width: 100%;
    table-layout: fixed;
    border-collapse: collapse !important;
    /* color: white; */
  }

  .ka-thead-background {
    font-size: 12px;
    background-color: rgb(0, 0, 0, 0);
  }

  .ka-thead-cell-height {
    box-sizing: border-box;
    height: 27px;
  }

  .ka-thead-fixed {
    position: sticky;
  }

  .ka-thead-row:nth-child(1) .ka-thead-fixed {
    top: 0px;
  }

  .ka-thead-row:nth-child(2) .ka-thead-fixed {
    top: 47px;
  }

  .ka-thead-row:nth-child(3) .ka-thead-fixed {
    top: 94px;
  }

  .ka-thead-row:nth-child(4) .ka-thead-fixed {
    top: 141px;
  }

  .ka-thead-row:nth-child(5) .ka-thead-fixed {
    top: 188px;
  }

  .ka-thead-row:nth-child(6) .ka-thead-fixed {
    top: 235px;
  }

  .ka-thead-cell {
    padding: 0px 0px;
    padding-top: 10px;
    /* color: #ffffff; */
    color: #67748e;
    font-size: 12px;
    font-weight: 800;
    border-bottom: 2px solid #f9fbfc5d;

    /* color: #747d86; */
    /* color: #ffffff; */
    /* border-bottom: 2px solid #f9fbfc5d; */
    /* border-bottom: 0px solid white; */
    /* border: 0px !important; */
  }

  .ka-thead-cell-wrapper {
    display: flex;
  }

  .ka-thead-cell-content-wrapper {
    width: 100%;
  }

  .ka-thead-cell-content {
    width: 100%;
  }

  .ka-tbody {
    height: 100%;
  }

  .ka-thead-cell,
  .ka-cell {
    text-align: left;
    /* color: white; */
    color: #67748e;
  }

  .ka-cell {
    padding: 5px 0px;
    line-height: 1.1rem;
    /* color: #353c44; */
    /* color: #ffffff;
  font-size: 14px;
  font-weight: 800; */

    /* border: solid 0px #e1ebf0; */
  }

  .ka-tree-cell {
    display: flex;
  }

  .ka-icon {
    font-weight: 100;
    font-size: 10px;
    font-family: 'icons';
  }

  .ka-input {
    max-width: 100%;
    border: 1px solid;
  }

  .ka-cell-editor-validation-error .ka-input {
    background: #ffe7e7;
    border: 1px solid #ff0c0c;
  }

  .ka-validation-message-container {
    position: absolute;
  }

  .ka-validation-message {
    font-size: 12px;
    /* color: white; */
    color: #67748e;
    background-color: #ff0c0c;
    padding: 0 10px;
    max-width: 200px;
  }

  .ka-empty-cell {
    width: 40px;
    min-width: 40px;
  }

  .ka-tree-empty-space {
    width: 20px;
  }

  .ka-tr {
    width: 100%;
  }

  .ka-no-data-row {
    height: 100px;
    text-align: center;
  }

  .ka-row {
    box-sizing: border-box;
    border-bottom: 0px solid #f9fbfc;
    border-top: 0px solid #f9fbfc;
  }

  .ka-row:hover {
    background-color: rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  .ka-dragged-row {
    opacity: 0.5;
  }

  .ka-drag-over-row {
    box-shadow: inset 0 7px 0px -4px #e1ebf0;
  }

  .ka-dragged-row ~ .ka-drag-over-row {
    box-shadow: inset 0 -7px 0px -4px #e1ebf0;
  }

  .ka-drag-over-column {
    box-shadow: inset 7px 0 0px -4px #e1ebf0;
  }

  .ka-dragged-column ~ .ka-drag-over-column {
    box-shadow: inset -7px 0 0px -4px #e1ebf0;
  }

  .ka-row-selected {
    background-color: #f7fcfd;
    /* background-color: red; */
  }

  .ka-group-row {
    background-color: #f9fbfc;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    /* border-top: 1px solid white; */
  }

  .ka-group-cell {
    padding: 8px 10px;
  }

  .ka-group-cell-content {
    display: flex;
    align-items: center;
  }

  .ka-icon-group-arrow {
    padding: 7px 5px 7px 10px;
    margin-right: 5px;
    cursor: pointer;
  }

  .ka-group-panel {
    display: flex;
  }

  .ka-group-panel-cell {
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .ka-group-panel-cell-remove {
    cursor: pointer;
    color: #b8bdc2;
    display: flex;
    fill: currentColor;
  }

  .ka-group-panel-text {
    color: #747d86;
    margin: 30px 20px;
  }

  .ka-icon-tree-arrow {
    padding: 0 10px 0 10px;
    margin-left: -10px;
    cursor: pointer;
  }

  .ka-icon-sort {
    margin-left: 3px;
  }

  .ka-icon-sort-arrow-up {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-icon-sort-arrow-down {
    color: inherit;
    fill: currentColor;
    position: relative;
    top: 1px;
  }

  .ka-pointer {
    cursor: pointer;
  }

  .ka-filter-row-cell {
    padding: 0 20px 15px 20px;
  }

  .ka-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5333333333);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ka-loading-active {
    position: relative;
  }

  .ka-loading-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: ka-loading-animation 1s linear infinite alternate;
    position: relative;
    left: -20px;
  }

  .ka-thead-cell-resize {
    user-select: none;
    width: 2px;
    cursor: col-resize;
    background-color: #d7e4eb;
    position: relative;
    left: 19px;
  }

  .ka-thead-cell-resize:active:after {
    content: '';
    display: block;
    position: fixed;
    cursor: col-resize;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ka-thead-background:active {
    z-index: 1;
  }

  .ka-summary-cell {
    padding: 12px 20px;
  }

  @keyframes ka-loading-animation {
    0% {
      background-color: rgb(116, 125, 134);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    25% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgb(116, 125, 134),
        40px 0px 0px 0px rgba(116, 125, 134, 0.2);
    }
    75% {
      background-color: rgba(116, 125, 134, 0.4);
      box-shadow: 20px 0px 0px 0px rgba(116, 125, 134, 0.2),
        40px 0px 0px 0px rgb(116, 125, 134);
    }
    100% {
      box-shadow: none;
    }
  }
  .ka-loading-text {
    margin-top: 15px;
    color: #353c44;
  }

  .ka-paging-sizes-active {
    display: flex;
    justify-content: space-between;
    /* border-top: 2px solid #94aab4; */
    border-top: 2px solid #f9fbfc5d;
  }

  .ka-paging-pages,
  .ka-paging-sizes {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 0px;
    margin: 0;
  }

  .ka-paging-pages {
    justify-content: flex-end;
  }

  .ka-paging-pages::before {
    content: 'Page:';
    margin: 5px 5px;
    text-align: center;
  }

  .ka-paging-page-index,
  .ka-paging-size {
    cursor: pointer;
    padding: 0 0px;
    margin: 5px 0px;
    margin-right: 5px;
    min-width: 18px;
    /* border-radius: 50%; */
    text-align: center;
    /* color: #e4e9f5; */
    color: #67748e;
    user-select: none;
  }

  .ka-paging-page-index-active,
  .ka-paging-size-active {
    /* background-color: #f1f5f7; */
    font-weight: bold;
    /* color: #747d86; */
    /* color: #ffffff; */
    color: #67748e;
    font-weight: 800;
    padding: 0px 3px;
  }

  .ka-popup {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    color: #747d86;
    padding: 15px 20px;
    position: absolute;
    text-align: center;
    width: 245px;
    max-height: 325px;
    overflow-y: auto;
    z-index: 1000;
  }

  .ka-popup-content-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f9fbfc;
  }

  .ka-popup-content-item-value {
    padding: 8px 20px;
  }

  .ka-header-filter-button-icon {
    font-size: 12px;
    position: absolute;
    top: -1px;
    color: #51575d;
    fill: currentColor;
  }

  .ka-header-filter-button {
    margin-left: 3px;
    position: relative;
  }

  .ka-header-filter-button-has-value {
    font-weight: bold;
  }
}

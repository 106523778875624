.card-clickable {
  cursor: 'pointer';
}
.card-clickable:hover {
  /* opacity: 0.8, */
  transition: 'all 0.5s ease';
  /* boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)', */
  box-shadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px';
  /* boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px', */
}
